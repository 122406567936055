import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import blogthum2 from "../images/Best-Practices-for-ADA-Websites.jpg"
import blogthum3 from "../images/How-to-Market-your-Multi-location-Business-with-Facebook-Ads.jpg"
import blogthum1 from "../images/Tips-and-Design-Principles-for-Multi-location-Websites.jpg"
import StayUpdatedSecure from "../images/We-help-you-overcome-challenges.png"
import Shapefour from "../images/bannerElement/white-design-element-healthcare.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import creativeContent from "../images/creative-content.svg"
import AppointmentOptimization from "../images/icons/HealthCare/Appointment-optimization.svg"
import Hippa from "../images/icons/HealthCare/HIPAA-compliance-expertise.svg"
import Omnichannel from "../images/icons/HealthCare/Omnichannel-patient-engagement.svg"
import ProvenRoi from "../images/icons/HealthCare/Proven-ROI-and-performance.svg"
import ReputationManagement from "../images/icons/HealthCare/Reputation-management.svg"
import TailoredDataDrivenStrategies from "../images/icons/HealthCare/Tailored-data-driven-strategies.svg"
import Audax from "../images/logos/HealthCare/Audax-Group.svg"
import Cranemere from "../images/logos/HealthCare/Cranemere.svg"
import North from "../images/logos/HealthCare/North.svg"
import Northstar from "../images/logos/HealthCare/Northstar.svg"
import OiA from "../images/logos/HealthCare/OiA.svg"
import Phoenix from "../images/logos/HealthCare/Phoenix.svg"
import Proudmoments from "../images/logos/HealthCare/Proud-moments.svg"
import marketingAutomation from "../images/marketing-automation.svg"
import paidMarketing from "../images/paid-marketing.svg"
import seo from "../images/seo.svg"
import webDevelopment from "../images/web-development.svg"
import "../styles/404.css"

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  )
}
const WebMasterServices = ({ siteTitle }) => {
  useEffect(() => {
    // Check if we're running in the browser environment
    if (typeof window !== "undefined") {
      // Check if the user agent contains "Safari" but not "Chrome"
      const isSafari = /^((?!chrome|android).)*Safari/i.test(
        navigator.userAgent
      )

      // Check if the browser is running on macOS or iOS
      const isMacOrIOS = /(Mac|iPhone|iPod|iPad)/.test(navigator.platform)

      // Combine the conditions
      if (isSafari && isMacOrIOS) {
        document.documentElement.classList.add("safari-mac")
      }
    }
  }, []) // Empty dependency array ensures the effect runs only once after component mount
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button")

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded")

      if (itemToggle === "false") {
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute("aria-expanded", "false")
        }
        this.setAttribute("aria-expanded", "true")
      } else {
        // Check if the clicked accordion is already open, if yes, do nothing
        if (itemToggle === "true") {
          return
        }
        this.setAttribute("aria-expanded", "false")
      }
    }

    items.forEach(item => item.addEventListener("click", toggleAccordion))

    return () => {
      items.forEach(item => item.removeEventListener("click", toggleAccordion))
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Healthcare</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body />
      </Helmet>
      <Layout>
        <div class="healthcare content-creative">
          <section id="Banner">
            <div class="container">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Build trust, Amplify care</h1>
                    <p>
                      Expand your patient community across multiple locations
                      with digital marketing.
                    </p>
                    <a className="button" href="/contact-us">
                      Free Consultation
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>
                Empowering physicians to deliver quality healthcare: Trust MSOs
                and marketers for business support
              </h2>
              <p>
                With increasing regulations and HIPAA compliance requirements,
                it has become increasingly difficult to expand your reach and
                build a strong patient community. Healthcare marketing is
                nuanced and differs per specialty. It is important to have a
                partner who has deep knowledge and demonstrated experience in
                delivering HIPAA-compliant solutions to your ecosystem.
              </p>
            </div>
          </section>
          <section id="TextImageSection" class="web-master-services">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Overcome your Healthcare marketing challenges</h2>
                  <p>
                    We understand that Healthcare Marketers and MSO groups have
                    unique challenges compared to other industry verticals.
                    Generally, the main goals are to:
                  </p>
                  <ul class="bullet-point">
                    <li>Increase patient volumes </li>
                    <li>Optimize appointments and reduce no-shows </li>
                    <li>Boost referral programs</li>
                    <li>
                      Recruit and retain physicians and healthcare professionals{" "}
                    </li>
                    <li>Build brand awareness and reputation management</li>
                  </ul>
                </div>
                <div class="img-wrap">
                  <div className="image-container">
                    <img
                      alt="Copy write"
                      width="500"
                      height="500"
                      src={StayUpdatedSecure}
                    />
                    <div class="floating-casestudy">
                      <a
                        href="https://www.position2.com/gd-case-study-location-based-optimization-for-a-better-ranking.pdf"
                        target="_blank"
                      >
                        <div class="casestudy">
                          <div class="case-wrap">
                            <p>
                              Gentle Dental's Location-Based Optimization for a
                              Better Ranking
                            </p>
                            <a
                              href="https://www.position2.com/gd-case-study-location-based-optimization-for-a-better-ranking.pdf"
                              target="_blank"
                            >
                              Read more <i class="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="WeDeliver">
            <div className="container">
              <h2>
                Healthcare marketing solutions with source-to-close insights
              </h2>
              <p>
                Our healthcare marketing solutions are designed to drive patient
                engagement, ensure compliance, optimize appointments, and
                enhance your online reputation.
              </p>
              <div className="horizontalImageIconSec">
                <div className="moduleWrap">
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={TailoredDataDrivenStrategies}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Tailored, data-driven strategies</h3>
                      <p>
                        We leverage advanced analytics to refine targeting and
                        messaging based on data-driven insights.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={Hippa} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>HIPAA compliance expertise</h3>
                      <p>
                        With in-depth knowledge of HIPAA regulations, we do
                        regular audits and updates to ensure ongoing compliance
                        with the latest guidelines.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={Omnichannel} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Omnichannel patient engagement</h3>
                      <p>
                        We deploy integrated, HIPAA-compliant campaigns across
                        multiple channels to Engage new and existing patients.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={ReputationManagement}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Reputation management</h3>
                      <p>
                        Proactive monitoring of online reviews and strategies to
                        encourage positive reviews and address negative
                        feedback.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img src={ProvenRoi} width={100} height={100} />
                    </div>
                    <div className="contents">
                      <h3>Proven ROI and performance</h3>
                      <p>
                        Continuos monitoring and transparent reporting on key
                        performance indicators (KPIs) and growth metrics is our
                        key.
                      </p>
                    </div>
                  </div>
                  <div className="eachModule">
                    <div className="icons">
                      <img
                        src={AppointmentOptimization}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div className="contents">
                      <h3>Appointment optimization</h3>
                      <p>
                        Strategies to reduce no-shows and last-minute
                        cancellations and Integration with appointment
                        scheduling systems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PartnerLogoSection">
            <div class="container">
              <h2>Proven for industry leaders</h2>
              <p>
                We have successfully delivered cutting-edge digital marketing
                services including HIPAA-compliant tech stack, ADA websites and
                AI-based solutions to many Private Equity (PE) portfolios,
                Management Services Organizations, (MSO), Dental Services
                Organizations (DSO) and other multi-location healthcare and
                wellness practices.
              </p>
              <div class="logo-wrap">
                <div class="each-logo">
                  <img src={Phoenix} alt="Phoenix" />
                </div>
                <div class="each-logo">
                  <img src={North} alt="North Star logo" />
                </div>
                <div class="each-logo">
                  <img src={Northstar} alt="Northstar" />
                </div>
                <div class="each-logo">
                  <img src={OiA} alt="OiA" />
                </div>
                <div class="each-logo">
                  <img src={Proudmoments} alt="Proud-moments" />
                </div>
              </div>
            </div>
          </section>
          <section id="IndustryLeaders">
            <div className="container">
              <div className="innerWrap">
                <h2 className="title">Proud partners of</h2>
                <div className="logos">
                  <img src={Audax} />
                  <img src={Cranemere} />
                </div>
              </div>
            </div>
          </section>
          <section id="OurServices">
            <div className="container">
              <h2>Our end-to-end services</h2>
              <div className="tilesWrap">
                <a className="eachtiles" href="/web-design-and-development/">
                  <img src={webDevelopment} />
                  <span>Website Development</span>
                </a>
                <a className="eachtiles" href="/creative-assets/">
                  <img src={creativeContent} />
                  <span>Creative & Content</span>
                </a>
                <a className="eachtiles" href="/paid-marketing/">
                  <img src={paidMarketing} />
                  <span>Paid Advertising</span>
                </a>
                <a className="eachtiles" href="/seo/">
                  <img src={seo} />
                  <span>SEO</span>
                </a>
                <a className="eachtiles" href="/marketing-automation/">
                  <img src={marketingAutomation} />
                  <span>Marketing Automation</span>
                </a>
              </div>
              <h2>We are your growth partner</h2>
              <p>
                Our formula is simple, we pair strategy with award-winning
                execution and delivery. We have subject-matter experts with
                healthcare backgrounds who learn your business and partner with
                you to deliver the best outcomes to grow your patient base,
                build reputation, amplify referrals and create a brand that you
                and your patients can be proud of.
              </p>
            </div>
          </section>
          <div id="Cards">
            <div className="container">
              <h2>Insights & trends</h2>
              <div className="cards-wrap">
                <a
                  className="each-card"
                  href="https://www.position2.com/blog/tips-and-design-principles-multi-location-websites/"
                >
                  <div className="bckImage">
                    <img
                      alt="How digital marketers can drive growth"
                      src={blogthum1}
                    />
                  </div>
                  <div className="textOverlay">
                    <div className="eyeBrow">
                      <span>Blog</span>
                    </div>
                    <div className="title">
                      <h5>
                        Tips and Design Principles for Multi-location Websites
                      </h5>
                    </div>
                    {/* <div className="time">
                      <p>December 20, 2023</p>
                    </div> */}
                  </div>
                </a>

                <a
                  href="https://www.position2.com/blog/best-practices-for-ada-website/"
                  className="each-card"
                >
                  <div className="bckImage">
                    <img alt="AI impact on marketing" src={blogthum2} />
                  </div>
                  <div className="textOverlay">
                    <div className="eyeBrow">
                      <span>Blog</span>
                    </div>
                    <div className="title">
                      <h5>Best Practices for ADA Websites</h5>
                    </div>
                    {/* <div className="time">
                      <p>December 20, 2023</p>
                    </div> */}
                  </div>
                </a>
                <a
                  href="https://www.position2.com/blog/how-market-your-multi-location-business-with-facebook-ads/"
                  className="each-card"
                >
                  <div className="bckImage">
                    <img alt="Redefining business with AI" src={blogthum3} />
                  </div>
                  <div className="textOverlay">
                    <div className="eyeBrow">
                      <span>Blog</span>
                    </div>
                    <div className="title">
                      <h5>
                        How to Market your Multi-location Business with Facebook
                        Ads
                      </h5>
                    </div>
                    {/* <div className="time">
                      <p>December 20, 2023</p>
                    </div> */}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <section id="contactus-section">
            <div class="container">
              <h2>Ready to scale patient care and healthcare business?</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default WebMasterServices
